import { APIFilter } from '@/utils/api'
import { TABLA, PRESUPUESTOCLI } from '@/utils/consts'

export default {
  async selectDetails (Vue, idcliente, esTecnico, esComercial, esVigilante) {
    let apiCalls = []
    const apiFilter = new APIFilter()
      .addExact('idcliente', idcliente)
      .addExact('estado', 1)
    apiCalls.push({ name: 'selectSistemaCount', method: 'sistema.select', params: { filter: apiFilter, wrapper: 'count' } })
    apiCalls.push({ name: 'selectFacturacliCount', method: 'facturacli.select', params: { filter: apiFilter, wrapper: 'count' } })
    apiCalls.push({ name: 'selectClienteCuentaCount', method: 'clienteCuenta.select', params: { filter: apiFilter, wrapper: 'count' } })
    apiCalls.push({ name: 'selectRepresentanteCount', method: 'representante.select', params: { filter: apiFilter, wrapper: 'count' } })
    apiCalls.push({ name: 'selectClienteDireccionCount', method: 'clienteDireccion.select', params: { filter: apiFilter, wrapper: 'count' } })
    apiCalls.push({ name: 'selectPersonasContactoCount', method: 'clienteTelefono.select', params: { filter: apiFilter, wrapper: 'count' } })
    apiCalls.push({ name: 'selectEstudioFinancieroCount', method: 'estudioFinanciero.select', params: { filter: apiFilter, wrapper: 'count' } })
    const apiFilterCRM = new APIFilter()
      .addExact('idcliente', idcliente)
    apiCalls.push({ name: 'selectOperacioncrmCount', method: 'operacioncrm.select', params: { filter: apiFilterCRM, wrapper: 'count' } })
    const apiFilterPresupuesto = new APIFilter()
    apiFilterPresupuesto
      .addExact('idcliente', idcliente)
      .addGT('idest_presupuestocli', PRESUPUESTOCLI.estados.anulado)
    apiCalls.push({ name: 'selectPresupuestoCount', method: 'presupuestocli.select', params: { filter: apiFilterPresupuesto, wrapper: 'count' } })
    apiCalls.push(Vue.$online.fichero.ApiCallSelectCount(idcliente, TABLA.cliente.idtabla, esTecnico, esComercial, esVigilante))
    return await Vue.$api.batchCall(apiCalls)
  },
  async selectCliente (Vue, idcliente) {
    const apiFilter = new APIFilter()
    apiFilter.addExact('idcliente', idcliente)
    const resp = await Vue.$api.call('cliente.select', { filter: apiFilter })
    return [resp.data.result.dataset[0], resp.data.result.metadata]
  },
  async calcRiesgoDisponible (Vue, idcliente) {
    const resp = await Vue.$api.call('cliente.calcRiesgoDisponible', { idcliente })
    return resp.data.result
  },
  async geolocalizar (Vue, idclienteDireccion, latitud, longitud) {
    const resp = await Vue.$api.call(
      'clienteDireccion.update', {
        values: {
          idcliente_direccion: idclienteDireccion,
          latitud,
          longitud,
        },
      })
    return resp.data.result
  },
}
