<template>
  <b10-base>
    <b10-toolbar
      :title="title"
      :options="toolbarOptions"
      @click-option="clickToolbarOption"
    />
    <b10-page-content>
      <b10-view-summary
        :item="formattedItem"
        :showing.sync="showingDialogs.moreInfo"
        :loading-data="loadingData"
        :show-alerts="!routeFromBrowserHistory"
        @click-row="clickSummaryRow"
      />
      <b10-view-details
        :details="details"
        @click-detail="clickDetail"
      />
      <b10-fab-button
        v-if="hasEditPerm(permissions.cliente.id)"
        :icon="$vuetify.icons.values.edit"
        @click="clickEdit"
      />
    </b10-page-content>
  </b10-base>
</template>

<script>
import _ from '@/utils/lodash'
import { viewPageMixin } from '@/mixins/viewPageMixin'
import { nonEmpty } from '@/utils/templates'
import Data from './ClienteViewData'
import { captureGeolocation, getLatLongURL } from '@/utils/maps'
import { get } from 'vuex-pathify'
import { ItemNotFoundError, TABLA, CLIENTE } from '@/utils/consts'

export default {
  mixins: [viewPageMixin],
  beforeRouteLeave (to, from, next) {
    return this.beforeRouteLeaveBase(to, from, next)
  },
  data () {
    return {
      moreInfoRows: [],
      showingDialogs: {
        moreInfo: false,
      },
      toolbarOptions: {
        addPresupuesto: {
          title: 'Presupuesto',
          visible: true,
          icon: 'add'
        },
        addEstudioFinanciero: {
          title: 'Estudio financiero',
          visible: true,
          icon: 'add'
        },
        map: {
          title: 'Ver en el mapa',
          visible: true,
          icon: 'map',
        },
        geolocalizar: {
          title: 'Capturar geolocalización actual',
          visible: true,
          icon: 'mapLocation',
        },
      },
      CLIENTE,
    }
  },
  computed: {
    usuarioIdtecnico: get('usuario/idtecnico'),
    usuarioIdcomercial: get('usuario/idcomercial'),
    usuarioIdvigilante: get('usuario/idvigilante'),
    formattedItem () {
      if (!_.isEmpty(this.item.dataset)) {
        const item = _.cloneDeep(this.item.dataset)
        item.id = item.idcliente
        item.title = this.$options.filters.linebreaksBr(nonEmpty`
          ${item.nombre} (${item.idcliente}) - ${item.cif}`)
        item.subtitle = this.$options.filters.linebreaksBr(nonEmpty`
          ${item.razon_social} - ${item.zona_descripcion}`)
        if (item.avisos) {
          item.alerts = [{
            value: this.$options.filters.linebreaksBr(item.avisos),
            type: 'warning',
            options: {
              persistent: true,
            },
          }]
        } else {
          item.alerts = []
        }
        if (item.estado === CLIENTE.estados.bloqueado) {
          item.badge = CLIENTE.descripcionEstado.bloqueado
          item.badgeColor = CLIENTE.colores.bloqueado
        }
        if (this.hasEditPerm(this.permissions.clienteDireccion.id) && (!item.latitud || !item.longitud)) {
          item.alerts.push({
            value: 'Cliente sin geolocalización',
            type: 'warning',
            options: {
              persistent: true,
              actions: [{ text: 'Capturar', flat: true, handler: this.geolocalizar }],
            },
          })
        }
        item.moreInfo = this.buildMoreInfo(this.item.metadata)
        return item
      } else {
        return {}
      }
    },
  },
  async created () {
    await this.initStore()
    await this.loadPage()
  },
  methods: {
    async loadPage () {
      await this.loadItem()
      this.title = this.item.dataset.nombre
      // asignar moreInfoRows en loadPage() para tener accesibles los permisos del store (no disponibles en data())
      this.moreInfoRows = {
        cliente: {
          title: 'Cliente',
          rows: [
            'idcliente',
            'nombre',
            'razon_social',
            'cif',
            { name: 'forma_pago_descripcion', visible: this.hasViewPerm(this.permissions.clientes.verFormaPago) },
            {
              name: 'iban_principal',
              label: 'IBAN principal',
              password: true,
              clickable: true,
              icon: this.$vuetify.icons.values.show,
              visible: this.hasViewPerm(this.permissions.clienteCuenta.id)
            },
            {
              name: 'riesgo_disponible',
              label: 'Riesgo disponible',
              visible: this.hasViewPerm(this.permissions.clientes.verRiesgoDisponible),
              value: this.$options.filters.currency(await Data.calcRiesgoDisponible(this, this.routeParams.idcliente)),
            },
            { name: 'nombre_representante_principal', label: 'Representante principal' },
            'tactividad_descripcion',
          ]
        },
        direcciones: {
          title: 'Direcciones',
          rows: [
            'zona_descripcion',
            { name: 'cliente_direccion', label: 'Dirección fiscal' },
            { name: 'cliente_direccion_postal', label: 'Dirección postal' },
            { name: 'cliente_direccion_servicio', label: 'Dirección de servicio' },
          ],
        },
        comerciales: {
          title: 'Datos comerciales',
          rows: [
            'agente_nombre',
            'comercial_nombre',
            'canal_conocimiento_descripcion',
          ]
        },
        otros: {
          title: 'Otros datos',
          rows: [
            'idioma_descripcion',
            'pais_descripcion',
          ]
        },
        comentarios: {
          title: 'Comentarios',
          rows: [
            'observaciones',
          ]
        },
      }
      this.toolbarOptions.geolocalizar.visible = this.hasEditPerm(this.permissions.clienteDireccion.id)
      await this.loadDetailTotals()
    },
    async loadItem () {
      this.loadingData = true
      try {
        const [dataset, metadata] = await Data.selectCliente(this, this.routeParams.idcliente)
        if (dataset) {
          this.$set(this.item, 'dataset', dataset)
          this.$set(this.item, 'metadata', metadata)
          this.toolbarOptions.map.visible = !!this.item.dataset.latitud && !!this.item.dataset.longitud
          this.toolbarOptions.addPresupuesto.visible = this.hasInsertPerm(this.permissions.presupuestocli.id) && !!this.usuarioIdcomercial
          this.toolbarOptions.addEstudioFinanciero.visible = this.hasInsertPerm(this.permissions.clienteEstudioFinanciero.id) && !!this.usuarioIdcomercial
        } else {
          const error = new ItemNotFoundError()
          error.extra = {
            title: 'Cliente no encontrado',
            message: `El cliente que intentas visualizar no existe. Puede haber sido eliminado.`,
            redirectRoute: { name: 'clientes__cliente-list' }
          }
          throw error
        }
      } finally {
        this.loadingData = false
      }
    },
    async loadDetailTotals () {
      this.initDetails()
      const resp = await Data.selectDetails(
        this,
        this.routeParams.idcliente,
        !!this.usuarioIdtecnico,
        !!this.usuarioIdcomercial,
        !!this.usuarioIdvigilante
      )
      // sistemas
      if (this.hasViewPerm(this.permissions.sistema.id)) {
        const detailSistemas = this.addDetail(
          'sistemas', 'Sistemas', 'Sistemas del cliente', 'sistema'
        )
        detailSistemas.badge = resp.data.selectSistemaCount.result.dataset[0].count || 0
      }
      // órdenes de trabajo
      this.detailOrdenesTrabajo = this.addDetail(
        'ordenes', 'Órdenes de trabajo', 'Órdenes de trabajo del cliente', 'orden'
      )
      // presupuestos
      if (this.hasViewPerm(this.permissions.presupuestocli.id)) {
        const detailPresupuestos = this.addDetail(
          'presupuestos', 'Presupuestos', 'Presupuestos del cliente', 'presupuestos'
        )
        detailPresupuestos.badge = resp.data.selectPresupuestoCount.result.dataset[0].count || 0
      }
      // contactos
      if (this.hasViewPerm(this.permissions.clienteTelefono.id)) {
        const detailContactos = this.addDetail(
          'contactos', 'Personas de contacto', 'Personas de contacto del cliente', 'personaContacto'
        )
        detailContactos.badge = resp.data.selectPersonasContactoCount.result.dataset[0].count || 0
      }
      // ficheros
      if (this.hasViewPerm(this.permissions.clienteAdjunto.id)) {
        const detailFicheros = this.addDetail(
          'adjuntos', 'Adjuntos', 'Fotografías y ficheros adjuntos', 'attach'
        )
        detailFicheros.badge = resp.data.selectFicheroCount.result.dataset[0].count || 0
      }
      // facturas
      if (this.hasViewPerm(this.permissions.clienteFacturas.id)) {
        const detailFacturas = this.addDetail(
          'facturas', 'Facturas', 'Facturas del cliente', 'facturas'
        )
        detailFacturas.badge = resp.data.selectFacturacliCount.result.dataset[0].count || 0
      }
      // cuentas bancarias
      if (this.hasViewPerm(this.permissions.clienteCuenta.id)) {
        const detailClienteCuenta = this.addDetail(
          'clienteCuenta', 'Cuentas bancarias', 'Cuentas bancarias del cliente', 'clienteCuenta'
        )
        detailClienteCuenta.badge = resp.data.selectClienteCuentaCount.result.dataset[0].count || 0
      }
      // representante
      if (this.hasViewPerm(this.permissions.clienteRepresentante.id)) {
        const detailRepresentante = this.addDetail(
          'representantes', 'Representantes', 'Representantes del cliente', 'representantes'
        )
        detailRepresentante.badge = resp.data.selectRepresentanteCount.result.dataset[0].count || 0
      }
      // direcciones
      if (this.hasViewPerm(this.permissions.clienteDireccion.id)) {
        const detailDirecciones = this.addDetail(
          'direcciones', 'Direcciones', 'Postal o fiscal', 'direcciones'
        )
        detailDirecciones.badge = resp.data.selectClienteDireccionCount.result.dataset[0].count || 0
      }
      // estudio finacniero
      if (this.hasViewPerm(this.permissions.clienteEstudioFinanciero.id)) {
        const detailEstudioFinanciero = this.addDetail(
          'estudiosFinancieros', 'Estudios financieros', 'Estudios financieros del cliente', 'estudioFinanciero'
        )
        detailEstudioFinanciero.badge = resp.data.selectEstudioFinancieroCount.result.dataset[0].count || 0
      }
      // operaciones CRM
      if (this.hasViewPerm(this.permissions.operaciones.id)) {
        const detailOperacionCRM = this.addDetail(
          'operacionCRM', 'Operaciones', 'Operaciones relacionadas con el cliente', 'operacionCRM'
        )
        detailOperacionCRM.badge = resp.data.selectOperacioncrmCount.result.dataset[0].count || 0
      }
    },
    clickDetail (data) {
      if (data.detail.name === 'sistemas') {
        this.$appRouter.push({
          name: 'sistemas__sistema-list',
          query: {
            idcliente: this.routeParams.idcliente,
          },
        })
      } else if (data.detail.name === 'adjuntos') {
        this.$appRouter.push({
          name: 'ficheros__clientes-fichero-list',
          params: {
            id: this.routeParams.idcliente,
            idtabla: TABLA.cliente.idtabla,
          },
        })
      } else if (data.detail.name === 'facturas') {
        this.$appRouter.push({
          name: 'facturas__facturacli-list',
          params: {
            idcliente: this.routeParams.idcliente,
          },
        })
      } else if (data.detail.name === 'clienteCuenta') {
        this.$appRouter.push({
          name: 'cuentasbancarias__clientes-cliente-cuenta-list',
          params: {
            idcliente: this.routeParams.idcliente,
          },
        })
      } else if (data.detail.name === 'representantes') {
        this.$appRouter.push({
          name: 'representantes__clientes-representante-list',
          params: {
            idcliente: this.routeParams.idcliente,
          },
        })
      } else if (data.detail.name === 'direcciones') {
        this.$appRouter.push({
          name: 'direcciones__clientes-cliente-direccion-list',
          params: {
            idcliente: this.routeParams.idcliente,
          },
        })
      } else if (data.detail.name === 'contactos') {
        this.$appRouter.push({
          name: 'telefonos__clientes-cliente-telefono-list',
          params: {
            idcliente: this.routeParams.idcliente,
          },
        })
      } else if (data.detail.name === 'estudiosFinancieros') {
        this.$appRouter.push({
          name: 'estudiosfinancieros__clientes-estudio-financiero-list',
          params: {
            idcliente: this.routeParams.idcliente,
          },
        })
      } else if (data.detail.name === 'presupuestos') {
        this.$appRouter.push({
          name: 'presupuestos__clientes-presupuestocli-list',
          params: {
            idcliente: this.routeParams.idcliente,
          },
        })
      } else if (data.detail.name === 'operacionCRM') {
        this.$appRouter.push({
          name: 'operacionescrm__clientes-operacioncrm-list',
          params: {
            idcliente: this.routeParams.idcliente,
          },
        })
      } else if (data.detail.name === 'ordenes') {
        this.$appRouter.push({
          name: 'clientes__ordenes-list',
          params: {
            idcliente: this.routeParams.idcliente,
          },
        })
      }

    },
    async clickToolbarOption (option) {
      if (option === this.toolbarOptions.map) {
        window.open(getLatLongURL(this.item.dataset.latitud, this.item.dataset.longitud), '_blank')
      } else if (option === this.toolbarOptions.geolocalizar) {
        this.geolocalizar()
      } else if (option === this.toolbarOptions.addPresupuesto) {
        this.$appRouter.push({
          name: 'presupuestos__clientes-presupuestocli-add',
          params: {
            idcliente: this.routeParams.idcliente,
          },
        })
      } else if (option === this.toolbarOptions.addEstudioFinanciero) {
        this.$appRouter.push({
          name: 'estudiosfinancieros__clientes-estudio-financiero-add',
          params: {
            idcliente: this.routeParams.idcliente,
          },
        })
      }
    },
    async geolocalizar () {
      const geolocation = await captureGeolocation(this)
      if (!!geolocation?.lat && geolocation?.lng) {
        const res = await this.$alert.showConfirm(
          `¿Deseas asignar la geolocalización actual (${geolocation.lat}, ${geolocation.lng})
          a la dirección del cliente "${this.item.dataset.cliente_direccion}"?`)
        if (res) {
          await Data.geolocalizar(
            this,
            this.item.dataset.idcliente_direccion,
            geolocation.lat,
            geolocation.lng
          )
          await this.loadItem()
        }
      }
    },
    async clickSummaryRow (row) {
      if (row.icon === this.$vuetify.icons.values.show) {
        this.$loading.showManual('Auditando acceso...')
        try {
          await this.$online.auditoria.auditarViewRecordClienteCuenta(this.item.dataset.idcliente_cuenta_principal)
        } finally {
          this.$loading.hide()
        }
        this.setMoreInfoRowValue(row.name, 'password', false, 'cliente')
        this.setMoreInfoRowValue(row.name, 'icon', this.$vuetify.icons.values.hide, 'cliente')
      } else {
        this.setMoreInfoRowValue(row.name, 'password', true, 'cliente')
        this.setMoreInfoRowValue(row.name, 'icon', this.$vuetify.icons.values.show, 'cliente')
      }
    },
    clickEdit () {
      this.$appRouter.push({
        name: 'clientes__cliente-edit',
        params: {
          idcliente: this.item.dataset.idcliente,
        },
      })
    },
  },
}
</script>
